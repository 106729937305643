<template>
  <div class="train-wrap">
    <Header v-if="!user" />
    <div class="train-main">
      <div class="train-content">
        <el-card class="box-card">
          <div slot="header" class="text-bold tac title">
            <!-- <el-page-header @back="$router.replace('/')" content="《食品标签规范化制作流程和技巧》专题培训" /> -->
            <el-page-header @back="$router.replace('/')">
                <h1 slot="title">《食品标签规范化制作流程和技巧》专题培训</h1>
            </el-page-header>
          </div>
          <div>
            <h3>一、培训对象</h3>
            <p>食品生产企业、餐饮企业、食品相关产品生产企业、检测机构、监管部门等单位与食品标签工作相关人员。</p>
            <h3>二、培训时长及地点</h3>
            <p>1天，到委托单位上门培训。</p>
            <h3>三、培训内容（最终根据企业要求确定）</h3>
            <p>
              1．GB 7718 《食品安全国家标准预包装食品标签通则》核心条款解读 </br>
              2．GB 28050《预包装食品营养标签通则》核心条款解读</br>
              3．《食品安全法实施条例》及《食品安全法实施条例》与标签相关部分核心条款解读</br>
              4．食品标签（含营养标签）类经典案例剖析</br>
            </p>
            <h3>四、课程焦点（根据企业要求确定）</h3>
            <p>
              1．预包装食品、散装食品、进口食品、现制现售食品、食用农产品、辐照食品、转基因食品、特殊食品（保健食品和特医食品）、餐饮食品、小作坊生产的食品标签，都有哪些适用法规和基础标准？</br>
              2．分装形式生产的食品，标签标识有什么要求？</br>
              3．委托加工的食品标签，标签标识有什么要求？</br>
              4．委托加工食品的标签出现错误，谁来担责？</br>
              5．进口食品标签，有什么规定？</br>
              6．餐饮食品，对食品标识有什么要求？</br>
              7．食品营养标签，有什么注意事项？</br>
              8．制作食品标签，需要提前准备哪些基础材料？</br>
              9．食品标签上的哪些内容需要醒目标识？</br>
              10．食品标签上使用的图片和文字，有哪些注意事项？</br>
              11．食品标签标识，字体大小有什么要求？</br>
              12．如何对食品进行正确命名？</br>
              13．如何确定食品原料的合法性？</br>
              14．食品配料表标注，有什么要求？</br>
              15．食品添加剂标注，有什么注意事项？</br>
              16．标注食品生产日期和保质期，有什么注意事项？</br>
              17．如何预防食品标签不合格？</br>
            </p>
            <h3>五、培训费用</h3>
            <p>金牌讲师：<span class="text-price">5000</span> 元/日，高级讲师：<span class="text-price">3000</span> 元/日</p>
            <h3>六、培训流程及合同下载</h3>
            <p>
              客户沟通 → 确定培训需求 → 合同签订 → 培训材料制作 → 培训实施 → 效果评估
            </p>
            <h4>
              合同下载：
            </h4>
            <p>如有培训需求，请联系池老师：13500018190  或者微信扫码联系“士海食标”公众号</p>
            <p class="tac" >
              <img style="width:auto;" src="https://img.shihaishibiao.com/sh/qrcode4mp.jpg" alt="" srcset="">
            </p>
            <h3>七、培训精彩回顾</h3>
            <p v-for="(item,index) in imgList" :key="index" >
            <img :src="item.src" />
            </p> 
          </div>
        </el-card>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
import { Header, Footer } from "@/page/login/components";
import { mapGetters } from "vuex";

export default {
  components: { Header, Footer },
  computed: {
    ...mapGetters(["user"]),
  },
  data() {
    return {
      imgList: [
        {
          src: require("./image/1.jpg"),
        },
        {
          src: require("./image/2.jpg"),
        },
        {
          src: require("./image/3.jpg"),
        },
        {
          src: require("./image/4.jpg"),
        },
        {
          src: require("./image/5.jpg"),
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    if (this.user && this.$route.path != "/index/train") {
      this.$router.replace("/index/train");
    }
  },
};
</script>
<style lang="scss" scoped>
.train-wrap {
  background: #fff;
  .return-btn {
    color: #fff;
    font-size: 16px;
    margin-right: 15px;
  }
  .avatar {
    vertical-align: top;
    margin-right: 15px;
  }
  .train-main {
    padding-top: 69px;
    max-width: 1000px;
    width: 100%;
    margin: auto;
    .train-content {
      margin: 10px 0;
      p {
        padding: 10px 0;
      }
      img {
        max-width: 100%;
        width: 100%;
      }
    }
  }
  .title {
    font-size: 22px;
  }
}
.text-bold {
  font-weight: bold;
}
</style>